/* global Helpers */
import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import fetcher from 'modules/fetcher'

import {
  userUpdate,

  userEditFormFailure,

  userFetchFailure,
  userFetchSuccess,

  userMarkInactiveFormFailure,
  userReassignDataFormFailure,
  userReactivateFormFailure
} from 'actions/User'

import {
  USER_DISABLE_MFA,
  USER_REQUEST_MFA,
  USER_BULK_ACTION,
  USER_EDIT_FORM_SUBMIT,

  USER_FETCH_REQUEST,

  USER_MARK_INACTIVE_FORM_SUBMIT,
  USER_REASSIGN_DATA_FORM_SUBMIT,
  USER_REACTIVATE_FORM_SUBMIT,
  USER_PROFILES_LAYOUT_FETCH,
  USER_PROFILES_LAYOUT_FETCH_SUCCESS,
  USER_PROFILES_LAYOUT_POSITIONS_UPDATE,
  USER_PROFILES_LAYOUT_HIDDEN_UPDATE
} from 'constants/User'

import { licenseInfoModal } from 'modals/ducks'
import { getRecordType } from 'utils'

export function * userFetchSaga ({ payload }) {
  try {
    const users = yield call(
      fetcher,
      '/api/v3/admin/users'
    )

    const { pagination: { pages, per_page } } = users
    let { entries: concatenatedUsers, pagination: { page } } = users

    while (page <= pages) {
      if (page > 1) {
        const response = yield call(
          fetcher,
          '/api/v3/admin/users',
          { query: { page, per_page } }
        )
        concatenatedUsers = concatenatedUsers.concat(response.entries)
      }

      page++
    }

    yield put(userFetchSuccess(concatenatedUsers))
  } catch (exception) {
    console.error(exception)

    yield put(userFetchFailure({ _error: exception.message }))
  }
}

export function * watchUserFetchSaga () {
  yield call(takeEvery, USER_FETCH_REQUEST, userFetchSaga)
}

export function * userEditFormSubmitSaga ({ payload: { id, reassign_to_user_id, ...payload } }) {
  try {
    if ([3, 5].includes(payload.level)) payload.team_id = null
    delete payload.is_external

    const response = yield call(
      fetcher,
      `/admin/users/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ user: payload })
      }
    )

    if (payload.read_only && reassign_to_user_id) { // eslint-disable-line camelcase
      yield call(
        fetcher,
        `/admin/users/${id}/reassign_data`,
        {
          method: 'PUT',
          body: JSON.stringify({ reassign_to_user_id })
        }
      )

      response.data_reassigned_at = new Date()
    }

    yield put(userUpdate({ ...payload, ...response }))
  } catch (exception) {
    console.error(exception)

    yield put(userEditFormFailure({ _error: exception.message }))
  }
}

export function * watchUserEditFormSubmitSaga () {
  yield call(takeEvery, USER_EDIT_FORM_SUBMIT, userEditFormSubmitSaga)
}

export function * userReassignDataFormSubmitSaga ({ payload: { id, reassign_to_user_id } }) {
  try {
    yield call(
      fetcher,
      `/admin/users/${id}/reassign_data`,
      {
        method: 'PUT',
        body: JSON.stringify({ reassign_to_user_id })
      }
    )

    yield put(userUpdate({ id, data_reassigned_at: new Date() }))
  } catch (exception) {
    console.error(exception)

    yield put(userReassignDataFormFailure({ _error: exception.message }))
  }
}

export function * watchUserReassignDataFormSubmitSaga () {
  yield call(takeEvery, USER_REASSIGN_DATA_FORM_SUBMIT, userReassignDataFormSubmitSaga)
}

export function * userDisableMfaSaga ({ payload: { id } }) {
  try {
    const response = yield call(
      fetcher,
      `/admin/users/${id}/disable_mfa`,
      {
        method: 'DELETE'
      }
    )

    yield put(userUpdate({ ...response }))
  } catch (exception) {
    console.error(exception)
  }
}

export function * watchUserDisableMfaSaga () {
  yield call(takeEvery, USER_DISABLE_MFA, userDisableMfaSaga)
}

export function * userRequestMfaSaga ({ payload: { id } }) {
  try {
    const response = yield call(
      fetcher,
      `/admin/users/${id}/request_mfa`,
      {
        method: 'PUT'
      }
    )

    yield put(userUpdate({ ...response }))
  } catch (exception) {
    console.error(exception)
  }
}

export function * watchUserRequestMfaSaga () {
  yield call(takeEvery, USER_REQUEST_MFA, userRequestMfaSaga)
}

export function * userBulkActionSaga ({ payload: { ids, bulkAction } }) {
  try {
    yield call(
      fetcher,
      '/admin/users/bulk_action',
      {
        method: 'POST',
        body: JSON.stringify({ ids, bulk_action: bulkAction })
      }
    )

    Helpers.notify('', 'The bulk action will be performed in the background. Please refresh the page after a few moment to see the changes.')
  } catch (exception) {
    console.error(exception)
  }
}

export function * watchUserBulkActionSaga () {
  yield call(takeEvery, USER_BULK_ACTION, userBulkActionSaga)
}

export function * userMarkInactiveFormSubmitSaga ({ payload: { id, reassign_to_user_id } }) {
  try {
    const deactivateResponse = yield call(
      fetcher,
      `/admin/users/${id}/make_inactive`,
      {
        method: 'DELETE',
        body: JSON.stringify({ reassign_to_user_id })
      }
    )

    if (deactivateResponse.display_license_info) {
      yield put(licenseInfoModal(
        {
          licenseCount: deactivateResponse.license_count,
          userCount: deactivateResponse.user_count,
          minimumLicenseCount: deactivateResponse.minimum_license_count,
          showMinimumLicenseCountMessage: deactivateResponse.minimum_license_count > 1,
          canRemoveLicenses: deactivateResponse.license_count > deactivateResponse.user_count && deactivateResponse.license_count > deactivateResponse.minimum_license_count,
          billingUrl: deactivateResponse.william_url
        }
      ))
    }

    yield put(
      userUpdate({
        id,
        data_reassigned_at: (reassign_to_user_id ? new Date() : undefined), // eslint-disable-line camelcase
        deleted_at: new Date()
      }))
  } catch (exception) {
    console.error(exception)

    yield put(userMarkInactiveFormFailure({ _error: exception.message }))
  }
}

export function * watchUserMarkInactiveFormSubmitSaga () {
  yield call(takeEvery, USER_MARK_INACTIVE_FORM_SUBMIT, userMarkInactiveFormSubmitSaga)
}

export function * userReactivateFormSubmitSaga ({ payload: { id } }) {
  try {
    const response = yield call(
      fetcher,
      `/admin/users/${id}/reactivate`,
      {
        method: 'PUT'
      }
    )

    if (response.display_license_info) {
      yield put(licenseInfoModal(
        {
          licenseCount: response.license_count,
          userCount: response.user_count,
          minimumLicenseCount: response.minimum_license_count,
          showMinimumLicenseCountMessage: response.minimum_license_count > 1,
          canRemoveLicenses: response.license_count > response.user_count && response.license_count > response.minimum_license_count,
          billingUrl: response.william_url
        }
      ))
    }

    yield put(
      userUpdate({
        id,
        deleted_at: null
      }))
  } catch (exception) {
    yield put(userReactivateFormFailure({ _error: exception.message }))
  }
}

export function * watchUserReactivateFormSubmitSaga () {
  yield call(takeEvery, USER_REACTIVATE_FORM_SUBMIT, userReactivateFormSubmitSaga)
}

export function * userProfilesLayoutSaga ({ payload }) {
  try {
    const response = yield call(fetcher, '/api/internal/users/profiles_layout', {
      method: 'GET'
    })
    yield put({ type: USER_PROFILES_LAYOUT_FETCH_SUCCESS, payload: response })
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchUserProfilesLayoutSaga () {
  yield call(
    takeEvery,
    USER_PROFILES_LAYOUT_FETCH,
    userProfilesLayoutSaga
  )
}

export function * userProfilesLayoutPositionUpdateSaga ({ payload: { profileType, columns } }) {
  try {
    yield call(
      fetcher, `/${profileType}/position`, {
        body: JSON.stringify({ ...columns }),
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'PUT'
      }
    )

    window.trackableEvent.records.dragAndDropTile({ 'Record Type': getRecordType(profileType) })
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchUserProfilesLayoutPositionUpdateSaga () {
  yield call(
    takeEvery,
    USER_PROFILES_LAYOUT_POSITIONS_UPDATE,
    userProfilesLayoutPositionUpdateSaga
  )
}

export function * userProfilesLayoutHiddenUpdateSaga ({ payload: { profileType, tile } }) {
  try {
    yield call(
      fetcher, `/${profileType}/hidden_tiles`, {
        body: JSON.stringify({ tile }),
        method: 'PUT'
      }
    )
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchUserProfilesLayoutHiddenUpdateSaga () {
  yield call(
    takeEvery,
    USER_PROFILES_LAYOUT_HIDDEN_UPDATE,
    userProfilesLayoutHiddenUpdateSaga
  )
}
