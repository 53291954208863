import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Section, Row, Left, Right } from 'components/BusinessCard/components/StyledComponents'
import { useBusinessCard } from 'components/BusinessCard/context'

const Fax = ({ faxAttrs }) => {
  const { cardMeta: { showOnlyFields }, resource } = useBusinessCard()

  const faxes = useMemo(() => {
    return faxAttrs.filter(attr => showOnlyFields.includes(attr)).map(attr => resource[attr]).filter(attr => !!attr)
  })

  if (!faxes.length) return null

  return (
    <Section>
      {faxes.map(fax =>
        <Row key={fax}>
          <Left>
            Fax
          </Left>
          <Right>{fax}</Right>
        </Row>
      )}
    </Section>
  )
}

Fax.propTypes = {
  faxAttrs: PropTypes.array.isRequired
}

export default Fax
