import {
  COMPANY_FETCH_SUCCESS,
  DEAL_CONDITIONAL_FIELDS_VALIDATION_UPDATE,
  DEAL_CONDITIONAL_FIELDS_VALIDATION,
  DEAL_COPY,
  DEAL_FETCH_ERROR,
  DEAL_FETCH_FILES,
  DEAL_FETCH_SUCCESS,
  DEAL_FETCH,
  DEAL_PERSON_NEW_SUCCESS,
  DEAL_PROFILE_FETCH,
  DEAL_PROFILE_RESET,
  DEAL_RELOAD_TODOS,
  DEAL_UPDATE,
  DEALS_FETCH_PEOPLE,
  DEALS_LOAD_PEOPLE,
  DEALS_UPDATE_ACTIVITY_FILTERS
} from 'Deals/constants'

export const dealProfileFetch = payload => ({ payload, type: DEAL_PROFILE_FETCH })
export const dealFetch = payload => ({ payload, type: DEAL_FETCH })
export const dealFetchSuccess = payload => ({ payload, type: DEAL_FETCH_SUCCESS })
export const dealFetchError = payload => ({ payload, type: DEAL_FETCH_ERROR })
export const dealUpdate = (payload, { successCallback, failureCallback, finallyCallback } = {}) => (
  { payload, type: DEAL_UPDATE, successCallback, failureCallback, finallyCallback })
export const updateActivityFilters = payload => ({ payload, type: DEALS_UPDATE_ACTIVITY_FILTERS })
export const reloadTodos = payload => ({ payload, type: DEAL_RELOAD_TODOS })
export const companyFetchSuccess = payload => ({ payload, type: COMPANY_FETCH_SUCCESS })
export const dealPersonNewSuccess = payload => ({ payload, type: DEAL_PERSON_NEW_SUCCESS })
export const dealsFetchPeople = payload => ({ payload, type: DEALS_FETCH_PEOPLE })

export const dealProfileReset = () => ({ type: DEAL_PROFILE_RESET })

export const dealCopy = (payload, successCallback) => ({ payload, type: DEAL_COPY, successCallback })

export const conditionalFieldsValidation = payload => ({ payload, type: DEAL_CONDITIONAL_FIELDS_VALIDATION })
export const updateConditionalFieldsValidation = payload => ({ payload, type: DEAL_CONDITIONAL_FIELDS_VALIDATION_UPDATE })

// Agenda
export const loadTodos = payload => ({ payload, type: 'DEALS_LOAD_TODOS' })
export const updateTodo = payload => ({ payload, type: 'DEALS_UPDATE_TODO' })
export const deleteTodo = payload => ({ payload, type: 'DEALS_DELETE_TODO' })
export const updateTodoFilters = payload => ({ payload, type: 'DEALS_UPDATE_TODO_FILTERS' })

// PEOPLE
export const loadPeople = payload => ({ payload, type: DEALS_LOAD_PEOPLE })
export const createPerson = payload => ({ payload, type: 'DEALS_CREATE_PERSON' })
export const updatePerson = payload => ({ payload, type: 'DEALS_UPDATE_PERSON' })
export const deletePerson = payload => ({ payload, type: 'DEALS_DELETE_PERSON' })
export const sortPeople = payload => ({ payload, type: 'DEALS_SORT_PEOPLE' })
export const filterPeople = payload => ({ payload, type: 'DEALS_FILTER_PEOPLE' })

// FILES
export const fetchFiles = payload => ({ payload, type: DEAL_FETCH_FILES })
export const loadFiles = payload => ({ payload, type: 'DEALS_LOAD_FILES' })
export const createFile = payload => ({ payload, type: 'DEALS_CREATE_FILE' })
export const deleteFile = payload => ({ payload, type: 'DEALS_DELETE_FILE' })
export const filterFiles = payload => ({ payload, type: 'DEALS_FILTER_FILES' })
export const updateFile = payload => ({ payload, type: 'DEALS_UPDATE_FILE' })
export const sortFiles = payload => ({ payload, type: 'DEALS_SORT_FILES' })

// COMPANY
export const updateCompany = payload => ({ payload, type: 'DEALS_UPDATE_COMPANY' })
