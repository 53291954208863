const initialState = {
  selectedActivities: [],
  selectedGameId: null,
  showGamificationModal: false
}

const gamificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'START_GAMIFICATION':
      return {
        ...state,
        ...action.payload
      }
    case 'STOP_GAMIFICATION':
      return {
        ...initialState,
        showGamificationModal: state.showGamificationModal
      }
    case 'SET_GAMIFICATION_MODAL_DISPLAY':
      return {
        ...state,
        showGamificationModal: action.payload
      }
    default:
      return state
  }
}

export default gamificationReducer
