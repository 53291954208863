const initialState = {
  companies: {},
  deals: {},
  people: {}
}

const UPDATE_LIST_VIEW_SEARCH = 'UPDATE_LIST_VIEW_SEARCH'

export const updateListViewSearch = payload => ({ payload, type: UPDATE_LIST_VIEW_SEARCH })

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LIST_VIEW_SEARCH:
      return { ...state, [action.payload.type]: action.payload.search }
    default:
      return state
  }
}
