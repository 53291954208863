import { VISIBLE_USERS_FETCH_SUCCESS } from 'VisibleUsers/constants'

const initialState = {
  accountUsersWithTeams: [],
  visibleUsersWithTeams: [],
  visibleUsersWithTeamsForCompanyOwner: [],
  visibleUsersWithTeamsForDealOwner: [],
  visibleUsersWithTeamsForPersonOwner: [],
  loaded: false
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VISIBLE_USERS_FETCH_SUCCESS:
      return {
        ...state,
        accountUsersWithTeams: action.payload.account_users_with_teams,
        visibleUsersWithTeams: action.payload.visible_users_with_teams,
        visibleUsersWithTeamsForCompanyOwner: action.payload.visible_users_with_teams_for_company_owner,
        visibleUsersWithTeamsForDealOwner: action.payload.visible_users_with_teams_for_deal_owner,
        visibleUsersWithTeamsForPersonOwner: action.payload.visible_users_with_teams_for_person_owner,
        loaded: true
      }
    default:
      return state
  }
}
