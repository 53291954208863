import React, { useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { selectItem } from './helpers'
import { useOnOutsideClick } from 'hooks/useOnOutsideClick'

import { DEAL_TYPE, PERSON_TYPE, COMPANY_TYPE } from './constants'
import { BusinessCardProvider } from './context'

import Person from './components/Person'
import PersonHeader from './components/PersonHeader'
import Deal from './components/Deal'
import DealFooter from './components/DealFooter'
import DealHeader from './components/DealHeader'

import Company from './components/Company'
import CompanyHeader from './components/CompanyHeader'

import { Sections, CardWrapper, PopoverWrapper } from './components/StyledComponents'
import CustomFields from './components/CustomFields'

const BusinessCard = ({
  avatarUpdateCallback,
  currentUser,
  element,
  fromCarousel,
  hideOnLeave,
  highlighter,
  isPrimary,
  itemData,
  itemId,
  markPrimary,
  onClick,
  onClose,
  onDeleteLinkClicked,
  onMarkPrimary,
  setIsOver,
  showAdditionalFields,
  showAssociationsCount,
  showDeleteLink,
  showEditLink,
  showTooltips,
  showNameAsLink,
  showOnlyFields,
  showPeopleCarousel,
  type,
  showEmptyFields,
  showMainDivider,
  wrapped
}) => {
  if (window.User.external) { return null }
  const node = useRef()
  const [item, setItem] = useState({})
  const [loaded, setLoaded] = useState(false)

  const onClickOutside = (e) => {
    return (element && onClose && !fromCarousel) && onClose(false)
  }

  useOnOutsideClick({ node, showComponent: true, onOutsideClick: onClickOutside })

  const reloadPerson = useCallback(e => {
    const eventItemId = e?.detail?.id

    if (eventItemId !== item.id) return

    selectItem(type, item.id).then(r => setItem(r))
  }, [item.id])

  const reloadCompany = useCallback(e => {
    const eventItemId = e?.detail?.id
    const itemCompanyId = item.company_id

    if (itemCompanyId !== eventItemId) return

    selectItem(type, item.id).then(r => setItem(r))
  }, [item.company_id])

  useEffect(() => {
    window.addEventListener('person:update', reloadPerson)
    window.addEventListener('company:update', reloadCompany)

    return () => {
      window.removeEventListener('person:update', reloadPerson)
      window.removeEventListener('company:update', reloadCompany)
    }
  }, [item.id, item.company_id])

  useEffect(() => {
    if (itemData) {
      setItem(itemData)
      setLoaded(true)
    } else {
      selectItem(type, itemId).then((r) => {
        setItem(r)
        setLoaded(true)
      })
    }
  }, [itemData, type, itemId])

  if (!loaded) return null

  return (
    <BusinessCardProvider
      avatarUpdateCallback={avatarUpdateCallback}
      currentUser={currentUser || window.User}
      element={element}
      fromCarousel={fromCarousel}
      hideOnLeave={hideOnLeave}
      highlighter={highlighter}
      isPrimary={isPrimary}
      linkifyName={showNameAsLink}
      markPrimary={markPrimary}
      onClick={onClick}
      onClose={onClose}
      onDeleteLinkClicked={onDeleteLinkClicked}
      onMarkPrimary={onMarkPrimary}
      resource={item}
      resourceId={item.id}
      resourceType={type}
      setIsOver={setIsOver}
      showAdditionalFields={showAdditionalFields}
      showAssociationsCount={showAssociationsCount}
      showDeleteLink={showDeleteLink}
      showEditLink={showEditLink}
      showTooltips={showTooltips}
      showOnlyFields={showOnlyFields}
      showPeopleCarousel={showPeopleCarousel}
      showEmptyFields={showEmptyFields}
      showMainDivider={showMainDivider}
    >
      <PopoverWrapper>
        <div ref={node}>
          <CardWrapper>
            {type === PERSON_TYPE && <PersonHeader />}
            {type === DEAL_TYPE && <DealHeader />}
            {type === COMPANY_TYPE && <CompanyHeader />}

            <Sections wrapped={wrapped}>
              {type === PERSON_TYPE && <Person />}
              {type === DEAL_TYPE && <Deal />}
              {type === COMPANY_TYPE && <Company />}
              <CustomFields />
            </Sections>

            {type === DEAL_TYPE && <DealFooter />}
          </CardWrapper>
        </div>
      </PopoverWrapper>
    </BusinessCardProvider>
  )
}

BusinessCard.propTypes = {
  avatarUpdateCallback: PropTypes.func,
  currentUser: PropTypes.object,
  element: PropTypes.object,
  fromCarousel: PropTypes.bool,
  hideOnLeave: PropTypes.bool,
  highlighter: PropTypes.string,
  isPrimary: PropTypes.bool,
  itemData: PropTypes.object,
  itemId: PropTypes.number.isRequired,
  markPrimary: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onDeleteLinkClicked: PropTypes.func,
  onMarkPrimary: PropTypes.func,
  setIsOver: PropTypes.func,
  showAdditionalFields: PropTypes.array,
  showAssociationsCount: PropTypes.bool,
  showDeleteLink: PropTypes.bool,
  showEditLink: PropTypes.bool,
  showTooltips: PropTypes.bool,
  showNameAsLink: PropTypes.bool,
  showOnlyFields: PropTypes.array,
  showPeopleCarousel: PropTypes.bool,
  type: PropTypes.string.isRequired,
  showEmptyFields: PropTypes.bool,
  showMainDivider: PropTypes.bool,
  wrapped: PropTypes.number // Ability to span multiple columns. Number is max-height of the card (below header). Zero or null for single column.
}

export {
  BusinessCard,
  DEAL_TYPE,
  PERSON_TYPE,
  COMPANY_TYPE
}
