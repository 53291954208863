import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  todoTemplateCreate,
  todoTemplateCreateFailure,
  todoTemplateUpdate,
  todoTemplateUpdateFailure,
  todoTemplateDelete,
  todoTemplateDeleteFailure
} from './actions'

import {
  TODO_TEMPLATE_CREATE_SUBMIT,
  TODO_TEMPLATE_UPDATE_SUBMIT,
  TODO_TEMPLATE_DELETE_SUBMIT,
  TODO_TEMPLATE_IGNORE_WEEKENDS
} from './constants'

export function * createSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/todo_templates',
      {
        method: 'POST',
        body: JSON.stringify({ todo_template: payload })
      }
    )
    yield put(todoTemplateCreate(response))
  } catch (e) {
    yield put(todoTemplateCreateFailure(e.message))
  }
}

export function * watchCreateSaga () {
  yield call(takeEvery, TODO_TEMPLATE_CREATE_SUBMIT, createSaga)
}

export function * updateSaga ({ payload }) {
  const request = {
    ...payload,
    todo_template_items_attributes: payload.todo_template_items_attributes.map(a => {
      const { event_category, ...rest } = a
      return rest
    })
  }
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/todo_templates/${request.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({ todo_template: request })
      }
    )
    yield put(todoTemplateUpdate(response))
  } catch (exception) {
    yield put(todoTemplateUpdateFailure(exception.message))
  }
}

export function * watchUpdateSaga () {
  yield call(takeEvery, TODO_TEMPLATE_UPDATE_SUBMIT, updateSaga)
}

export function * deleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/todo_templates/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(todoTemplateDelete(payload))
  } catch (exception) {
    console.error(exception.message)
    yield put(todoTemplateDeleteFailure(exception.message))
  }
}

export function * watchDeleteSaga () {
  yield call(takeEvery, TODO_TEMPLATE_DELETE_SUBMIT, deleteSaga)
}

export function * todoTemplateIgnoreWeekendsSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      'api/v3/admin/account_settings/todo_templates_ignore_weekends',
      {
        method: 'PUT',
        body: JSON.stringify({
          value: payload
        })
      }
    )
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchTodoTemplateIgnoreWeekendsSaga () {
  yield call(takeEvery, TODO_TEMPLATE_IGNORE_WEEKENDS, todoTemplateIgnoreWeekendsSaga)
}
