import { takeEvery, takeLatest } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'
import { getPersonSelector } from './selectors'

import store from 'store'
import { anyFieldsToUpdate, updateResourceThroughModal } from 'ConditionalFields/helpers/updaters'
import conditionalFieldsValidator from 'ConditionalFields/validator'

import {
  loadDeals,
  loadTodos,
  personFetchError,
  personFetchSuccess,
  personFetchSuccessEmailSequences,
  personFetchSuccessMailchimp,
  updateConditionalFieldsValidation
} from 'People/actions'

import {
  PEOPLE_FETCH_DEALS,
  PEOPLE_FETCH_MAILCHIMP,
  PERSON_CONDITIONAL_FIELDS_VALIDATION,
  PERSON_FETCH_EMAIL_SEQUENCES,
  PERSON_FETCH,
  PERSON_PROFILE_FETCH,
  PERSON_RELOAD_TODOS,
  PERSON_RELOAD,
  PERSON_UPDATE
} from 'People/constants'

import { normalizeDeals } from 'People/utils'

function * reloadPersonSaga ({ payload }) {
  const person = yield select(getPersonSelector)
  setTimeout(() => store.dispatch(personFetchSuccess(person)), 0)
}

function * fetchPersonSaga ({ payload }) {
  try {
    if (payload.window && payload.window.id === payload.personId) {
      yield put(personFetchSuccess(payload.window))
    } else {
      const response = yield call(
        fetcher,
        `/people/${payload.personId}`, {
          method: 'GET'
        }
      )
      yield put(personFetchSuccess(response))
    }
  } catch (exception) {
    console.error(exception.message)
    const errorBody = yield exception.response.json()
    yield put(personFetchError(errorBody.error))
  }
}

function * reloadTodosSaga () {
  const person = yield select(getPersonSelector)
  try {
    const responseTodos = yield call(
      fetcher,
      `/api/internal/people/${person.id}/agenda`, {
        method: 'GET'
      }
    )
    yield put(loadTodos(responseTodos))
  } catch (exception) {
    throw new Error(exception.message)
  }
}

function * fetchDealsSaga ({ payload }) {
  try {
    const person = yield select(getPersonSelector)
    const personId = (payload && (payload.id || payload.personId)) || person.id

    const params = {}
    if (payload.archivedDeals === true) params.archived_deals = 'true'

    const response = yield call(
      fetcher,
      `/api/internal/people/${personId}/deals`,
      {
        method: 'GET',
        query: params
      }
    )

    yield put(loadDeals(normalizeDeals(response)))
  } catch (exception) {
    throw new Error(exception.message)
  }
}

function * personProfileMailchimpSaga () {
  const person = yield select(getPersonSelector)
  try {
    const response = yield call(
      fetcher,
      `/api/internal/people/${person.id}/mailchimp`,
      {
        method: 'GET'
      }
    )
    yield put(personFetchSuccessMailchimp(response.entries))
  } catch (exception) {
    throw new Error(exception.message)
  }
}

function * fetchPersonEmailSequencesSaga () {
  const person = yield select(getPersonSelector)

  try {
    const response = yield call(
      fetcher,
      `/api/internal/people/${person.id}/email_sequence_recipients`, {
        method: 'GET'
      }
    )
    yield put(personFetchSuccessEmailSequences(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * personUpdateSaga ({ payload, successCallback, failureCallback, finallyCallback }) {
  const person = yield select(getPersonSelector)
  const personId = payload.id || person.id

  const resource = personId === person.id ? person : {}
  const conditionalFieldsParams = { resourceType: 'person', resource, values: payload }

  if (anyFieldsToUpdate(conditionalFieldsParams)) {
    updateResourceThroughModal({
      ...conditionalFieldsParams,
      resourceFetchSuccess: personFetchSuccess,
      closeCallback: () => {
        // Force misc components to reload with the correct data
        store.dispatch({ type: PERSON_RELOAD })
      },
      successCallback,
      finallyCallback
    })

    return
  }

  try {
    const response = yield call(
      fetcher,
      `/people/${personId}`, {
        body: JSON.stringify({ person: { ...payload } }),
        method: 'PATCH'
      }
    )

    yield put(personFetchSuccess(response))
    if (successCallback) {
      yield call(successCallback, response)
    }
  } catch (exception) {
    console.error(exception.message)

    if (failureCallback) {
      yield call(failureCallback, exception)
    }
  } finally {
    if (finallyCallback) {
      yield call(finallyCallback)
    }
  }
}

function * updateConditionalFieldsValidationSaga () {
  const person = yield select(getPersonSelector)

  try {
    const conditionalFieldsValidations = conditionalFieldsValidator('person', person)
    yield put(updateConditionalFieldsValidation(conditionalFieldsValidations))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * watchReloadPersonSaga () {
  yield call(takeEvery, PERSON_RELOAD, reloadPersonSaga)
}

function * watchPersonProfileMailchimpSaga () {
  yield call(takeEvery, PEOPLE_FETCH_MAILCHIMP, personProfileMailchimpSaga)
}

function * watchFetchPersonSaga () {
  yield call(takeLatest, [PERSON_PROFILE_FETCH, PERSON_FETCH], fetchPersonSaga)
}

function * watchFetchDealsSaga () {
  yield call(takeLatest, [PERSON_PROFILE_FETCH, PEOPLE_FETCH_DEALS], fetchDealsSaga)
}

function * watchReloadPersonTodosSaga () {
  yield call(takeEvery, PERSON_RELOAD_TODOS, reloadTodosSaga)
}

function * watchPersonFetchEmailSequencesSaga () {
  yield call(takeLatest, [PERSON_FETCH_EMAIL_SEQUENCES], fetchPersonEmailSequencesSaga)
}

function * watchPersonUpdateSaga () {
  yield call(takeEvery, PERSON_UPDATE, personUpdateSaga)
}

function * watchUpdateConditionalFieldsValidationSaga () {
  yield call(takeEvery, PERSON_CONDITIONAL_FIELDS_VALIDATION, updateConditionalFieldsValidationSaga)
}

export const personProfileSagas = [
  watchReloadPersonSaga(),
  watchFetchPersonSaga(),
  watchReloadPersonTodosSaga(),
  watchPersonProfileMailchimpSaga(),
  watchFetchDealsSaga(),
  watchPersonFetchEmailSequencesSaga(),
  watchPersonUpdateSaga(),
  watchUpdateConditionalFieldsValidationSaga()
]
