import React, { useMemo } from 'react'
import ReactTooltip from 'react-tooltip'

import { useBusinessCard } from 'components/BusinessCard/context'

import { UPDATE, COMPANY, DEAL, PERSON } from 'permissions'
import addEditRecord from 'add_edit_modals'

import { PermissibleButton } from 'components/Permissible'
import { PERSON_TYPE, DEAL_TYPE } from 'components/BusinessCard/constants'
const EditLink = () => {
  const {
    cardMeta: {
      showEditLink,
      showTooltips
    },
    callbacks: {
      onClose
    },
    currentUser,
    resourceId,
    resourceType
  } = useBusinessCard()

  const openEditModal = e => {
    e.stopPropagation()

    if (onClose) onClose(false)

    addEditRecord({ resourceType: resourceType.toLowerCase(), resourceId })
  }

  const policy = useMemo(() => {
    switch (resourceType) {
      case PERSON_TYPE:
        return PERSON
      case DEAL_TYPE:
        return DEAL
      default:
        return COMPANY
    }
  }, [resourceType])

  if (!showEditLink) return null

  return (
    <PermissibleButton
      action={UPDATE}
      readonly
      policy={policy}
      user={currentUser}
      cypress={`bc-edit-${resourceType}`}
    >
      <button
        className="edit-resource ui-corner-all btn btn-link normal"
        style={{ padding: 0 }}
        onClick={openEditModal}
      >
        <span
          data-class="tooltip-react tooltip-md"
          data-for="edit-tooltip"
          data-tip="Edit"
          className="show-on-hover"
        >
          <i className="fas fa-pencil-alt" style={{ fontSize: '12px' }} />
        </span>
        {showTooltips && <ReactTooltip id="edit-tooltip" className="react-tooltip" />}
      </button>
    </PermissibleButton>
  )
}

export default EditLink
