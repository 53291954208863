import React from 'react'

import { useBusinessCard } from 'components/BusinessCard/context'

import {
  Section,
  Left,
  Row,
  Right
} from 'components/BusinessCard/components/StyledComponents'

const DealOwner = () => {
  const {
    cardMeta: {
      showOnlyFields,
      showEmptyFields
    },
    resource: person
  } = useBusinessCard()

  const showOwner = showOnlyFields.includes('owner')

  if (!showEmptyFields && !showOwner) return null

  return (
    <Section>
      <Row>
        <Left>Owner</Left>
        <Right className="owner">{person.owner}</Right>
      </Row>
    </Section>
  )
}

export default DealOwner
