import { takeEvery, takeLatest } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'
import conditionalFieldsValidator from 'ConditionalFields/validator'
import { getCustomerSelector, getCustomerCompanySelector } from './selectors'
import { customerCompanyFetchSuccess, customerFetchSuccess, loadTodos, loadVisibleUsers, updateConditionalFieldsValidation, customerFetchSuccessChildrenCompanies } from 'Customers/actions'
import store from 'store'
import { anyFieldsToUpdate, updateResourceThroughModal } from 'ConditionalFields/helpers/updaters'

import {
  CUSTOMER_COMPANY_RELOAD,
  CUSTOMER_COMPANY_UPDATE,
  CUSTOMER_CONDITIONAL_FIELDS_VALIDATION,
  CUSTOMER_FETCH_VISIBLE_USERS,
  CUSTOMER_FETCH,
  CUSTOMER_PROFILE_FETCH,
  CUSTOMER_RELOAD_TODOS,
  CUSTOMER_FETCH_CHILDREN_COMPANIES
} from './constants'

function * reloadCustomerCompanySaga () {
  const company = yield select(getCustomerCompanySelector)
  setTimeout(() => store.dispatch(customerCompanyFetchSuccess(company)), 0)
}

function * fetchProfileSaga ({ payload }) {
  try {
    if (payload.window && payload.window.id === payload.customerId) {
      yield put(customerFetchSuccess(payload.window))
    } else {
      const response = yield call(fetcher, `/customers/${payload.customerId}`, { method: 'GET' })
      yield put(customerFetchSuccess(response))
    }
  } catch (exception) {
    console.error(exception.message)
    const errorBody = yield exception.response.json()
    yield put(customerFetchSuccess(errorBody.error))
  }
}

function * reloadTodosSaga () {
  const customer = yield select(getCustomerSelector)
  try {
    const responseTodos = yield call(
      fetcher,
      `/api/internal/customers/${customer.id}/agenda`,
      {
        method: 'GET'
      }
    )
    yield put(loadTodos(responseTodos))
  } catch (exception) {
    throw new Error(exception.message)
  }
}

function * loadCustomerVisibleUsersSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `/api/internal/customers/${payload.customerId}/visible_users`, {
        method: 'GET'
      }
    )
    yield put(loadVisibleUsers(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * watchFetchProfileSaga () {
  yield call(
    takeLatest,
    [CUSTOMER_PROFILE_FETCH, CUSTOMER_FETCH],
    fetchProfileSaga
  )
}

function * customerCompanyUpdateSaga ({ payload, successCallback, failureCallback, finallyCallback }) {
  const companyId = payload.id
  const company = yield select(getCustomerCompanySelector)

  const resource = companyId === company.id ? company : {}
  const conditionalFieldsParams = { resourceType: 'company', resource, values: payload }

  if (anyFieldsToUpdate(conditionalFieldsParams)) {
    updateResourceThroughModal({
      ...conditionalFieldsParams,
      resourceFetchSuccess: customerCompanyFetchSuccess,
      closeCallback: () => {
        // Force misc components to reload with the correct data
        store.dispatch({ type: CUSTOMER_COMPANY_RELOAD })
      },
      successCallback,
      finallyCallback
    })

    return
  }

  try {
    const response = yield call(
      fetcher,
      `/companies/${companyId}`, {
        body: JSON.stringify({ company: { ...payload } }),
        method: 'PATCH'
      }
    )

    yield put(customerCompanyFetchSuccess(response))
    if (successCallback) {
      yield call(successCallback, response)
    }
  } catch (exception) {
    console.error(exception.message)

    if (failureCallback) {
      yield call(failureCallback, exception)
    }
  } finally {
    if (finallyCallback) {
      yield call(finallyCallback)
    }
  }
}

function * fetchChildrenCompaniesSaga () {
  const company = yield select(getCustomerCompanySelector)

  try {
    const response = yield call(
      fetcher,
      `/api/v3/companies/${company.id}/children_companies`, {
        method: 'GET'
      }
    )
    yield put(customerFetchSuccessChildrenCompanies(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * updateConditionalFieldsValidationSaga () {
  const company = yield select(getCustomerCompanySelector)

  try {
    const conditionalFieldsValidations = conditionalFieldsValidator('company', company)
    yield put(updateConditionalFieldsValidation(conditionalFieldsValidations))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * watchReloadCustomerCompanySaga () {
  yield call(takeEvery, CUSTOMER_COMPANY_RELOAD, reloadCustomerCompanySaga)
}

function * watchReloadCustomerTodosSaga () {
  yield call(takeEvery, CUSTOMER_RELOAD_TODOS, reloadTodosSaga)
}

function * watchLoadCustomerVisibleUsersSaga () {
  yield call(takeLatest, [CUSTOMER_PROFILE_FETCH, CUSTOMER_FETCH_VISIBLE_USERS], loadCustomerVisibleUsersSaga)
}

function * watchCustomerCompanyUpdateSaga () {
  yield call(takeEvery, CUSTOMER_COMPANY_UPDATE, customerCompanyUpdateSaga)
}

function * watchUpdateConditionalFieldsValidationSaga () {
  yield call(takeEvery, CUSTOMER_CONDITIONAL_FIELDS_VALIDATION, updateConditionalFieldsValidationSaga)
}

function * watchCustomerFetchChildrenCompaniesSaga () {
  yield call(takeEvery, CUSTOMER_FETCH_CHILDREN_COMPANIES, fetchChildrenCompaniesSaga)
}

export const customerProfileSagas = [
  watchReloadCustomerCompanySaga(),
  watchFetchProfileSaga(),
  watchReloadCustomerTodosSaga(),
  watchLoadCustomerVisibleUsersSaga(),
  watchCustomerCompanyUpdateSaga(),
  watchUpdateConditionalFieldsValidationSaga(),
  watchCustomerFetchChildrenCompaniesSaga()
]
