import React, { useMemo } from 'react'
import pluralize from 'pluralize'

import {
  Section,
  Row,
  Left,
  Right
} from 'components/BusinessCard/components/StyledComponents'

import {
  H6
} from '@PipelineDeals/shared-react-components'

import { useBusinessCard } from 'components/BusinessCard/context'

import Addresses from 'components/BusinessCard/components/Addresses'
import CompanyName from 'components/BusinessCard/components/CompanyName'
import CreatedUpdated from 'components/BusinessCard/components/CreatedUpdated'
import Deals from 'components/BusinessCard/components/Deals'
import Description from 'components/BusinessCard/components/Description'
import Emails from 'components/BusinessCard/components/Emails'
import Fax from 'components/BusinessCard/components/Fax'
import LeadStatusFormated from 'components/LeadStatusFormated'
import PersonOwner from 'components/BusinessCard/components/PersonOwner'
import PhoneNumbers from 'components/BusinessCard/components/PhoneNumbers'
import SocialSection from 'components/BusinessCard/components/SocialSection'
import Tags from 'components/BusinessCard/components/Tags'
import Websites from 'components/BusinessCard/components/Websites'
import SimpleSection from './SimpleSection'

const Person = () => {
  const {
    cardMeta: {
      showAssociationsCount,
      showOnlyFields
    },
    resource: person
  } = useBusinessCard()

  const associationsCount = useMemo(() => {
    if (!showAssociationsCount) return null

    const dealCount = `(${person.deal_ids?.length}) ${pluralize('Deal', person.deal_ids?.length)}`
    return (
      <Section>
        <Row>
          <Left />
          <Right>
            <H6>{dealCount}</H6>
          </Right>
        </Row>
      </Section>
    )
  }, [person, showAssociationsCount])

  return (
    <>
      <PersonOwner />

      <SimpleSection field="position" value={person.position} label="Position" />
      <CompanyName />
      <CreatedUpdated />
      <PhoneNumbers phoneAttrs={['phone', 'home_phone', 'mobile']} />
      <Emails emailAttrs={['email', 'email2', 'home_email']} />

      {showOnlyFields.includes('lead_status_id') && person.lead_status?.id && (
        <Section>
          <Row>
            <Left>Status</Left>
            <Right>
              <LeadStatusFormated name={person.lead_status.name} color={person.lead_status.hex_color} />
            </Right>
          </Row>
        </Section>
      )}

      {showOnlyFields.includes('lead_source_id') && person.lead_source?.id && (
        <Section>
          <Row>
            <Left>Source</Left>
            <Right>{person.lead_source.name}</Right>
          </Row>
        </Section>
      )}

      <Fax faxAttrs={['fax']} />
      <Websites websiteAttrs={['website']} />
      <Addresses addressTypes={['work', 'company', 'home']} />
      <Description />
      <SocialSection />
      <Tags />
      <Deals />

      {showAssociationsCount && associationsCount}
    </>
  )
}

export default Person
