import React from 'react'
import PropTypes from 'prop-types'

import sanitizeAndLinkifyHtml from 'modules/sanitizeAndLinkifyHtml'

import {
  Section,
  Row,
  Left,
  Right
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

import styled from 'styled-components'
export const DivClamp = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
`

const Description = ({ field, value, label }) => {
  const { cardMeta: { showOnlyFields }, resource } = useBusinessCard()

  let displayValue
  if (field) {
    if (!showOnlyFields.includes(field)) return null

    displayValue = value
  } else {
    const description = showOnlyFields.includes('description')
    const summary = showOnlyFields.includes('summary')

    if (!description && !summary) return null

    displayValue = resource.description || resource.summary
  }

  return (
    <Section>
      <Row>
        <Left title={label || 'Summary'}>
          {label || 'Summary'}
        </Left>
        <Right>
          <DivClamp dangerouslySetInnerHTML={{ __html: sanitizeAndLinkifyHtml(displayValue) }} />
        </Right>
      </Row>
    </Section>
  )
}

Description.propTypes = {
  field: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string
}

export default Description
