import moment from 'moment'
import update from 'immutability-helper'
import {
  DEAL_CONDITIONAL_FIELDS_VALIDATION_UPDATE,
  DEAL_FETCH_ERROR,
  DEAL_FETCH_FILES,
  DEAL_FETCH_SUCCESS,
  DEAL_FETCH,
  DEAL_PERSON_FILTER_ALL,
  DEAL_PERSON_NEW_SUCCESS,
  DEAL_PROFILE_FETCH,
  DEAL_PROFILE_RESET,
  DEAL_RELOAD,
  DEALS_LOAD_PEOPLE,
  DEALS_UPDATE_ACTIVITY_FILTERS
} from 'Deals/constants'
import { ACTIVITY_ANY_TIME, GENERIC_FILTER_ANY } from 'Profiles/constants/activities'
import { FETCH_STATES } from 'Profiles/constants/fetch'

const userId = window.ppd && window.ppd.User ? window.ppd.User.id : 0
export const initialState = {
  dealId: null,
  deal: {},
  fetching: FETCH_STATES.fetching,
  collapsedTiles: [],
  tilePositions: {},
  agenda: [],
  agendaFilters: {
    ownerFilter: userId,
    dateFilter: 'ANY_TIME'
  },
  people: [],
  peopleSort: { field: 'first_name', direction: 'asc' },
  peopleFilters: {
    personFullName: '',
    personEmail: '',
    personPhone: '',
    personRelationship: '',
    personPosition: '',
    personOwner: [],
    personUpdatedAt: {},
    personIsKeyContact: ''
  },
  activityFilters: {
    dateFilter: ACTIVITY_ANY_TIME,
    personFilter: DEAL_PERSON_FILTER_ALL,
    activityTabFilters: {
      [GENERIC_FILTER_ANY]: true
    },
    emailTabFilters: {
      [GENERIC_FILTER_ANY]: true,
      Email: false,
      'Trackable Email Action': false
    },
    searchQuery: '',
    currentTab: 'ALL'
  },
  files: [],
  filesFetching: FETCH_STATES.fetching,
  fileSort: { field: 'created_at', direction: 'desc' },
  fileFilters: {
    fileTitle: '',
    fileOwner: [],
    fileUpdatedAt: {},
    fileSize: {},
    fileTags: []
  },
  reloading: false,
  conditionalFieldsValidation: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DEAL_RELOAD:
      return { ...state, reloading: true }
    case DEAL_PROFILE_RESET:
      return { ...initialState }
    case DEAL_PROFILE_FETCH:
      return { ...initialState, dealId: action.payload.dealId }
    case DEAL_FETCH:
      return { ...state, dealId: action.payload.dealId, fetching: FETCH_STATES.fetching }
    case DEAL_FETCH_SUCCESS:
      // DO NOT USE window.readOnly. This is for Historical/Restorable permissions only. See: webpack/permissions/permissible.js
      window.readOnly = Boolean(action.payload.valid_at)

      return {
        ...update(state, { deal: { $set: action.payload } }),
        fetching: FETCH_STATES.fetchSuccess,
        reloading: false
      }
    case DEAL_FETCH_ERROR:
      return { ...initialState, fetching: { ...FETCH_STATES.fetchError, fetchError: action.payload } }
    case DEAL_PERSON_NEW_SUCCESS:
      return update(state, {
        people: { $push: [action.payload] }
      })
    case 'DEALS_LOAD_TODOS':
      return update(state, {
        agenda: {
          $set: action.payload.map(todo => (
            { ...todo, moment_due_date: todo.unformatted_due_date && moment(todo.unformatted_due_date, ['MM-DD-YY', 'YYYY/MM/DD HH:mm:ss Z']) }
          ))
        }
      })
    case 'DEALS_UPDATE_TODO':
      return { ...state, agenda: state.agenda.map(todo => todo.id === action.payload.id ? action.payload : todo) }
    case 'DEALS_DELETE_TODO':
      return { ...state, agenda: state.agenda.filter(todo => todo.id !== action.payload.id) }
    case 'DEALS_UPDATE_TODO_FILTERS':
      return { ...state, agendaFilters: Object.assign({}, state.agendaFilters, action.payload) }

    case DEALS_LOAD_PEOPLE:
      return { ...state, people: action.payload }
    case 'DEALS_UPDATE_PERSON':
      return { ...state, people: state.people.map((person) => person.id === action.payload.id ? action.payload : person) }
    case 'DEALS_SORT_PEOPLE':
      return { ...state, peopleSort: action.payload }
    case 'DEALS_FILTER_PEOPLE':
      return { ...state, peopleFilters: action.payload }

    case DEAL_FETCH_FILES:
      return { ...state, filesFetching: FETCH_STATES.fetching }
    case 'DEALS_LOAD_FILES':
      return { ...state, files: action.payload, filesFetching: FETCH_STATES.fetchSuccess }
    case 'DEALS_UPDATE_FILE':
      return { ...state, files: state.files.map((file) => file.id === action.payload.id ? action.payload : file) }
    case 'DEALS_CREATE_FILE':
      return { ...state, files: state.files.concat([action.payload]) }
    case 'DEALS_DELETE_FILE':
      return { ...state, files: state.files.filter(file => file.id !== action.payload.id) }
    case 'DEALS_SORT_FILES':
      return { ...state, fileSort: action.payload }
    case 'DEALS_FILTER_FILES':
      return { ...state, fileFilters: action.payload }

    case DEALS_UPDATE_ACTIVITY_FILTERS:
      return { ...state, activityFilters: { ...state.activityFilters, ...action.payload } }
    case DEAL_CONDITIONAL_FIELDS_VALIDATION_UPDATE:
      return { ...state, conditionalFieldsValidation: action.payload }
    default:
      return state
  }
}
