import { takeEvery } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  calendarEntryPostponeCreate,
  calendarEntryPostponeCreateFailure,
  calendarEntryPostponeDelete,
  calendarEntryPostponeDeleteFailure
} from '../actions'

import {
  CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE_SUBMIT,
  CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE_SUBMIT,
  CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS, CALENDAR_ENTRY_USE_CALENDAR_TO_POSTPONE
} from '../constants'

import { getCalendarEntryPostponePeriods } from 'selectors/Account'

export function * calendarEntryPostponeCreateSaga ({ payload }) {
  const calendarEntryPostponePeriods = yield select(getCalendarEntryPostponePeriods)
  const newCalendarEntryPostponePeriods = [...calendarEntryPostponePeriods, payload.value].sort((a, b) => a - b)
  try {
    yield call(
      fetcher,
      'api/v3/admin/account_settings/calendar_entry_postpone_periods',
      {
        method: 'PUT',
        body: JSON.stringify({
          value: newCalendarEntryPostponePeriods
        })
      }
    )
    yield put(calendarEntryPostponeCreate(newCalendarEntryPostponePeriods))
  } catch (exception) {
    console.error(exception.message)
    yield put(calendarEntryPostponeCreateFailure(exception.message))
  }
}

export function * watchCalendarEntryPostponeCreateSaga () {
  yield call(takeEvery, CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE_SUBMIT, calendarEntryPostponeCreateSaga)
}

export function * calendarEntryPostponeDeleteSaga ({ payload }) {
  const calendarEntryPostponePeriods = yield select(getCalendarEntryPostponePeriods)
  const newCalendarEntryPostponePeriods = calendarEntryPostponePeriods.filter(period => period !== payload.value)
  try {
    yield call(
      fetcher,
      'api/v3/admin/account_settings/calendar_entry_postpone_periods',
      {
        method: 'PUT',
        body: JSON.stringify({
          value: newCalendarEntryPostponePeriods
        })
      }
    )
    yield put(calendarEntryPostponeDelete(newCalendarEntryPostponePeriods))
  } catch (exception) {
    yield put(calendarEntryPostponeDeleteFailure(exception.message))
  }
}

export function * watchCalendarEntryPostponeDeleteSaga () {
  yield call(takeEvery, CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE_SUBMIT, calendarEntryPostponeDeleteSaga)
}

export function * calendarEntryPostponeWeekendsSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      'api/v3/admin/account_settings/calendar_entry_postpone_weekends',
      {
        method: 'PUT',
        body: JSON.stringify({
          value: payload
        })
      }
    )
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchCalendarEntryPostponeWeekendsSaga () {
  yield call(takeEvery, CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS, calendarEntryPostponeWeekendsSaga)
}

export function * calendarEntryUseCalendarToPostponeSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      'api/v3/admin/account_settings/calendar_entry_use_calendar_to_postpone',
      {
        method: 'PUT',
        body: JSON.stringify({
          value: payload
        })
      }
    )
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchCalendarEntryUseCalendarToPostponeSaga () {
  yield call(takeEvery, CALENDAR_ENTRY_USE_CALENDAR_TO_POSTPONE, calendarEntryUseCalendarToPostponeSaga)
}
