/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.Helpers) { window.Helpers = {} }

const userGrouping = require('modules/userGrouping').default;

Handlebars.registerHelper('userForDropdown', function (fullName, deletedAt) {
  if (deletedAt) {
    return `${fullName} ⊘`
  } else {
    return fullName
  }
})

Helpers.groupedUsers = function (users) {
  return userGrouping(users)
}

Handlebars.registerHelper('externalUserTooltip', () => {
  let linkText, linkUrl

  if (window.User.currentExperiments.external_user_tooltip) {
    linkText = 'Start trial'
    linkUrl = '//www.pipelinecrm.com/get-started'
    trackingEvent = 'trackableEvent.externalUsers.clickStartTrialTooltip();'
  } else {
    linkText = 'Request demo'
    linkUrl = '//www.pipelinecrm.com/demo'
    trackingEvent = 'trackableEvent.externalUsers.clickRequestDemoTooltip();'
  }

  return new Handlebars.SafeString(`
    <div class="external-user-tooltip">
      <div>Unlock these features today.</div>

      <a class="btn btn-small btn-success" href="${linkUrl}" onclick="${trackingEvent}">${linkText}</a>
    </div>
  `)
})
