import store from 'store'
import { getVisibleUsers } from 'selectors/Account'

const nameWithMarkup = (fullName) => {
  return `\
      <span class="inactive_user"> ${fullName}
        <span data-toggle="tooltip" title="inactive">&#8856;</span>
      </span>\
    `
}

export default (id, message) => {
  if (message == null) { message = '' }

  if (!id) { return message }

  const users = getVisibleUsers(store.getState())
  const user = users.find(user => user.id === id)
  if (!user) { return 'Unknown user' }

  if (user.deleted_at) {
    return nameWithMarkup(user.full_name)
  } else {
    return user.full_name
  }
}
