import moment from 'moment'
import update from 'immutability-helper'

import {
  COMPANIES_CREATE_FILE,
  COMPANIES_DELETE_FILE,
  COMPANIES_DELETE_TODO,
  COMPANIES_FILTER_FILES,
  COMPANIES_FILTER_PEOPLE,
  COMPANIES_LOAD_FILES,
  COMPANIES_LOAD_PEOPLE,
  COMPANIES_LOAD_TODOS,
  COMPANIES_SORT_FILES,
  COMPANIES_SORT_PEOPLE,
  COMPANIES_UPDATE_ACTIVITY_FILTERS,
  COMPANIES_UPDATE_FILE,
  COMPANIES_UPDATE_PERSON,
  COMPANIES_UPDATE_TODO_FILTERS,
  COMPANIES_UPDATE_TODO,
  COMPANY_ACTIVITY_ANY_TIME,
  COMPANY_CONDITIONAL_FIELDS_VALIDATION_UPDATE,
  COMPANY_FETCH_ERROR,
  COMPANY_FETCH_SUCCESS,
  COMPANY_FETCH,
  COMPANY_COMPANY_FILTER_ALL,
  COMPANY_PERSON_FILTER_ALL,
  COMPANY_PERSON_NEW_SUCCESS,
  COMPANY_PROFILE_FETCH,
  COMPANY_PROFILE_RESET,
  COMPANY_RELOAD,
  COMPANY_FETCH_SUCCESS_CHILDREN_COMPANIES,
  COMPANY_FILTER_CHILDREN_COMPANIES,
  COMPANY_SORT_CHILDREN_COMPANIES
} from 'Companies/constants'
import { GENERIC_FILTER_ANY } from 'Profiles/constants/activities'
import { FETCH_STATES } from 'Profiles/constants/fetch'

const userId = window.ppd && window.ppd.User ? window.ppd.User.id : 0
export const initialState = {
  companyId: null,
  company: {},
  fetching: FETCH_STATES.fetching,
  agenda: [],
  agendaFilters: {
    ownerFilter: userId,
    dateFilter: 'ANY_TIME'
  },
  people: [],
  peopleSort: { field: 'first_name', direction: 'asc' },
  peopleFilters: {
    personFullName: '',
    personCompany: [],
    personEmail: '',
    personPhone: '',
    personOwner: [],
    personType: [],
    personLeadStatus: [],
    personUpdatedAt: {}
  },
  deals: [],
  dealSort: { field: 'updated_at', direction: 'desc' },
  dealFilters: {
    dealName: '',
    dealCompany: [],
    dealOwner: [],
    dealPrimaryContact: [],
    dealStage: [],
    dealStatus: [],
    dealUpdatedAt: {},
    dealValue: {}
  },
  activityFilters: {
    dateFilter: COMPANY_ACTIVITY_ANY_TIME,
    companyFilter: COMPANY_COMPANY_FILTER_ALL,
    personFilter: COMPANY_PERSON_FILTER_ALL,
    activityTabFilters: {
      [GENERIC_FILTER_ANY]: true
    },
    emailTabFilters: {
      [GENERIC_FILTER_ANY]: true,
      Email: false,
      'Trackable Email Action': false
    },
    searchQuery: '',
    currentTab: 'ALL'
  },
  files: [],
  fileSort: { field: 'created_at', direction: 'desc' },
  fileFilters: {
    fileTitle: '',
    fileOwner: [],
    fileUpdatedAt: {},
    fileSize: {},
    fileTags: []
  },
  reloading: false,
  conditionalFieldsValidation: {},
  childrenCompanies: [],
  childrenCompaniesSort: { field: 'name', direction: 'asc' },
  childrenCompaniesFilters: { name: [], owner: [], updatedAt: {}, value: {} }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_RELOAD:
      return { ...state, reloading: true }
    case COMPANY_PROFILE_RESET:
      return { ...initialState }
    case COMPANY_PROFILE_FETCH:
      return { ...initialState, companyId: action.payload.companyId }
    case COMPANY_FETCH:
      return { ...state, companyId: action.payload.companyId, fetching: FETCH_STATES.fetching }
    case COMPANY_FETCH_SUCCESS:
      return {
        ...update(state, { company: { $set: action.payload } }),
        fetching: FETCH_STATES.fetchSuccess,
        reloading: false
      }
    case COMPANY_FETCH_ERROR:
      return { ...initialState, fetching: { ...FETCH_STATES.fetchError, fetchError: action.payload } }

    // People
    case COMPANIES_LOAD_PEOPLE:
      return { ...state, people: action.payload }
    case COMPANIES_UPDATE_PERSON:
      return { ...state, people: state.people.map((person) => person.id === action.payload.id ? action.payload : person) }
    case COMPANIES_SORT_PEOPLE:
      return { ...state, peopleSort: action.payload }
    case COMPANIES_FILTER_PEOPLE:
      return { ...state, peopleFilters: action.payload }
    case COMPANY_FILTER_CHILDREN_COMPANIES:
      return { ...state, childrenCompaniesFilters: action.payload }
    case COMPANY_SORT_CHILDREN_COMPANIES:
      return { ...state, childrenCompaniesSort: action.payload }
    case COMPANY_PERSON_NEW_SUCCESS:
      return update(state, {
        people: { $push: [action.payload] }
      })

    // Deals
    case 'COMPANIES_LOAD_DEALS':
      return { ...state, deals: action.payload }
    case 'COMPANIES_SORT_DEALS':
      return { ...state, dealSort: action.payload }
    case 'COMPANIES_FILTER_DEALS':
      return { ...state, dealFilters: action.payload }

    // Agenda
    case COMPANIES_LOAD_TODOS:
      return update(state, {
        agenda: {
          $set: action.payload.map(todo => (
            { ...todo, moment_due_date: todo.unformatted_due_date && moment(todo.unformatted_due_date, ['MM-DD-YY', 'YYYY/MM/DD HH:mm:ss Z']) }
          ))
        }
      })
    case COMPANIES_UPDATE_TODO:
      return { ...state, agenda: state.agenda.map(todo => todo.id === action.payload.id ? action.payload : todo) }
    case COMPANIES_DELETE_TODO:
      return { ...state, agenda: state.agenda.filter(todo => todo.id !== action.payload.id) }
    case COMPANIES_UPDATE_TODO_FILTERS:
      return { ...state, agendaFilters: Object.assign({}, state.agendaFilters, action.payload) }

    // Files
    case COMPANIES_LOAD_FILES:
      return { ...state, files: action.payload }
    case COMPANIES_UPDATE_FILE:
      return { ...state, files: state.files.map((file) => file.id === action.payload.id ? action.payload : file) }
    case COMPANIES_CREATE_FILE:
      return { ...state, files: state.files.concat([action.payload]) }
    case COMPANIES_DELETE_FILE:
      return { ...state, files: state.files.filter(file => file.id !== action.payload.id) }
    case COMPANIES_SORT_FILES:
      return { ...state, fileSort: action.payload }
    case COMPANIES_FILTER_FILES:
      return { ...state, fileFilters: action.payload }

    // Activities
    case COMPANIES_UPDATE_ACTIVITY_FILTERS:
      return { ...state, activityFilters: { ...state.activityFilters, ...action.payload } }

    case COMPANY_CONDITIONAL_FIELDS_VALIDATION_UPDATE:
      return { ...state, conditionalFieldsValidation: action.payload }
    case COMPANY_FETCH_SUCCESS_CHILDREN_COMPANIES:
      return update(state, {
        childrenCompanies: {
          $set: action.payload
        }
      })
    default:
      return state
  }
}
