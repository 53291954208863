import React, { useMemo } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import {
  colors,
  SecondaryBadge,
  DisabledBadge
} from '@PipelineDeals/shared-react-components'

import { PermissibleLink } from 'components/Permissible'
import { UPDATE, PERSON } from 'permissions'

import { useBusinessCard } from 'components/BusinessCard/context'
import { COMPANY_TYPE, DEAL_TYPE, PERSON_TYPE } from '../constants'

import KeyContactIcon from 'components/KeyContactIcon'
import IdBadge from 'components/BusinessCard/components/IdBadge'
import Highlighter from 'components/BusinessCard/components/Highlighter'
import EditLink from 'components/BusinessCard/components/EditLink'

import { Name as NameStyled, NameWrapper } from './StyledComponents'

const IsPrimaryWrapper = styled.span`
  vertical-align: text-top;
  display: inline-block;
  margin: 0 5px 5px 5px;
  flex: 1 0 auto;
`

const RemoveLink = styled.a`
  float: right;
  color: ${colors.structure.quaternary};
  font-size: 12px;
  margin-top: 5px;

  &:hover {
    color: ${colors.text.primary};
  }
`

const Name = () => {
  const {
    cardMeta: {
      highlighter,
      isPrimary,
      linkifyName,
      markPrimary,
      showDeleteLink,
      showEditLink
    },
    callbacks: {
      onClick,
      onDeleteLinkClicked,
      onMarkPrimary
    },
    resource,
    resourceType
  } = useBusinessCard()

  const name = useMemo(() => {
    const resourceName = resource.name || resource.full_name || `${resource.first_name} ${resource.last_name}`
    return `${resourceName}${resource.is_sample ? ' (sample)' : ''}`
  }, [resource.name, resource.full_name, resource.first_name, resource.last_name, resource.is_sample])

  const url = useMemo(() => {
    switch (resourceType) {
      case COMPANY_TYPE:
        return `/companies/${resource.id}`
      case DEAL_TYPE:
        return `/deals/${resource.id}`
      default:
        return `/people/${resource.id}`
    }
  }, [resource.id, resourceType])

  return (
    <NameStyled>
      {resourceType === PERSON_TYPE && <KeyContactIcon person={resource} />}

      <NameWrapper>
        {linkifyName ? (
          <a href={url} onClick={onClick} title={name}>
            <Highlighter term={highlighter}>{name}</Highlighter>
          </a>
        ) : (
          <span title={name}><Highlighter term={highlighter}>{name}</Highlighter></span>
        )}
        <IdBadge />
      </NameWrapper>

      {showDeleteLink && (
        <RemoveLink
          onClick={onDeleteLinkClicked}
          data-cypress="bc-disassociate-person"
          data-for="delete-tooltip"
          data-tip="Remove"
          className="show-on-hover"
        >
          <i className="far fa-trash-alt" />
          <ReactTooltip id="delete-tooltip" className="react-tooltip" />
        </RemoveLink>
      )}

      {showEditLink && <EditLink />}

      {
        markPrimary && (
          <PermissibleLink
            cypress="bc-mark-primary-contact"
            action={UPDATE}
            policy={PERSON}
            user={window.User}
          >
            <IsPrimaryWrapper>
              {
                isPrimary ? <a data-toggle="tooltip" className="disabled"><SecondaryBadge content="Primary" /></a>
                  : <a onClick={onMarkPrimary} data-toggle="tooltip" title="Make primary contact"><DisabledBadge content="Primary" /></a>
              }
            </IsPrimaryWrapper>
          </PermissibleLink>
        )
      }
    </NameStyled>)
}

export default Name
