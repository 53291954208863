import { createSelector } from 'reselect'
import { filterActiveUsers } from 'modules/userFiltering'

export const getAccountUsersWithTeams = state => state.visibleUsers.accountUsersWithTeams

export const getVisibleUsersWithTeams = state => state.visibleUsers.visibleUsersWithTeams

export const getVisibleActiveUsers = createSelector(
  getVisibleUsersWithTeams,
  visibleUsers => {
    const visibleUsersWithoutTeams = visibleUsers.filter(user => !user.id.toString().endsWith('_team'))
    return filterActiveUsers(visibleUsersWithoutTeams)
  }
)

export const getVisibleUsersWithTeamsForCompanyOwner = state => state.visibleUsers.visibleUsersWithTeamsForCompanyOwner

export const getVisibleUsersWithTeamsForDealOwner = state => state.visibleUsers.visibleUsersWithTeamsForDealOwner

export const getVisibleUsersWithTeamsForPersonOwner = state => state.visibleUsers.visibleUsersWithTeamsForPersonOwner
