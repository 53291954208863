import React from 'react'

import PropTypes from 'prop-types'
import mailToPipelineUserLink from 'modules/mailToPipelineUserLink'

import { Section, Row, Left, Right } from 'components/BusinessCard/components/StyledComponents'
import { useBusinessCard } from 'components/BusinessCard/context'
import Highlighter from 'components/BusinessCard/components/Highlighter'

const ContactInfoEmails = ({ emailAttrs }) => {
  const { cardMeta: { highlighter, showOnlyFields }, resource, resourceId, resourceType } = useBusinessCard()

  const emailLabel = (attr) => {
    return {
      email: resourceType === 'Company' ? 'Email' : 'Work Email',
      email2: 'Other Email',
      home_email: 'Home Email'
    }[attr]
  }

  const emailValue = (attr) => {
    return resource[attr]
  }

  const parsedEmails = emailAttrs.map(attr => ({ attr, value: emailValue(attr), label: emailLabel(attr) })).filter(email => Boolean(email.value))

  if (!parsedEmails.length) return null
  if (!showOnlyFields.some(field => parsedEmails.map(p => p.attr).includes(field))) return null

  return (
    <Section>
      {parsedEmails.map((email, index) => {
        if (!showOnlyFields.includes(email.attr)) return null

        return (
          <Row key={`${resourceId}-${index}-send-email-to-${email.attr}`}>
            <Left>{email.label}</Left>
            <Right>
              <span>
                {mailToPipelineUserLink(email.value, <Highlighter term={highlighter}>{email.value}</Highlighter>)}
              </span>
            </Right>
          </Row>
        )
      })}
    </Section>
  )
}

ContactInfoEmails.propTypes = {
  emailAttrs: PropTypes.array.isRequired
}

export default ContactInfoEmails
