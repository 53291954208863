import { takeLatest } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import fetcher from 'modules/fetcher'

import { visibleUsersFetchSuccess } from 'VisibleUsers/actions'

import { LOAD_APP } from 'ducks'
import { USER_CREATE, USER_UPDATE } from 'constants/User'

export function * loadVisibleUsersSaga () {
  try {
    const response = yield call(
      fetcher,
      '/api/v3/visible_users', {
        method: 'GET'
      }
    )
    yield put(visibleUsersFetchSuccess(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchLoadAppSaga () {
  yield call(takeLatest, [LOAD_APP, USER_CREATE, USER_UPDATE], loadVisibleUsersSaga)
}
