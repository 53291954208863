import React from 'react'

import {
  SectionTitle,
  Row
} from 'components/BusinessCard/components/StyledComponents'

import Name from 'components/BusinessCard/components/Name'

const Deal = () => {
  return (
    <>
      <SectionTitle>
        <Row>
          <div style={{ maxWidth: '100%' }} data-cypress="business-card-deal-name"><Name /></div>
        </Row>
      </SectionTitle>
    </>
  )
}

export default Deal
