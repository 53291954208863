/* global _, PpdStorage, jQuery */

import * as methodsToAdd from './modules'

import pipelineMixpanel from './pipelineMixpanel'
if (!window.pipelineMixpanel) window.pipelineMixpanel = pipelineMixpanel

const getLocation = pathname => {
  switch (true) {
    case pathname === '/people':
      return 'People list view'
    case pathname === '/deals':
      return 'Deals list view'
    case pathname === '/kanban/deals':
      return 'Deals kanban view'
    case pathname === '/companies':
      return 'Companies list view'
    case pathname === '/to_dos':
      return 'Agenda list view'
    case pathname === '/calendar':
      return 'Calendar tab'
    case /companies\/\d+/.test(pathname):
      return 'Company profile page'
    case /people\/\d+/.test(pathname):
      return 'Person profile page'
    case /deals\/\d+/.test(pathname):
      return 'Deal profile page'
    case /connect/.test(pathname):
      return 'Connect tab'
    case pathname === '/':
      return 'Home page'
    case /dashboards(\/\d+)?/.test(pathname):
      return 'Home page'
    case /admin/.test(pathname):
      return 'Admin tab'
    case /user/.test(pathname):
      return 'My Profile tab'
    case /reporting(\/\d+)?/.test(pathname):
      return 'Reporting page'
    case /campaigns\/\d+\/reports/.test(pathname):
      return 'Email campaign reporting page'
    default:
      return pathname
  }
}

const convertMethodName = (methodName) => {
  // Converts methodNames to Method Names
  const result = methodName.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

const addMethods = (object, methodObject, prevKeys = []) => {
  // Adds methods to the trackableEvent object with standard naming and output
  //
  // Initially `object` is the trackableEvent object for the defined use case
  //
  // `methodObject` is a multi-dimensional object with an array of strings as leaves
  // Initially this is the methodsToAdd object imported from the modules folder
  // For example:
  // {
  //   admin: {
  //     users: ['create', 'edit']
  //   },
  //   general: ['createDeal', 'createEvent']
  // }
  //
  // This will add the following methods to the trackableEvent object:
  // trackableEvent.admin.users.create
  // trackableEvent.admin.users.edit
  // trackableEvent.general.createDeal
  // trackableEvent.general.createEvent
  //
  // Mixpanel Event names will be:
  // Admin : Users : Create
  // Admin : Users : Edit
  // General : Create Deal
  // General : Create Event

  Object.entries(methodObject).forEach(([key, value]) => {
    const nextObject = object[key] ||= {}
    if (Array.isArray(value)) {
      value.forEach((method) => {
        const startString = prevKeys.length ? `${prevKeys.join(' : ')} : ` : ''
        nextObject[method] = (props) => {
          window.trackableEvent.trackEvent(`${startString}${convertMethodName(key)} : ${convertMethodName(method)}`, props)
        }
      })
    } else {
      addMethods(nextObject, value, prevKeys.concat(convertMethodName(key)))
    }
  })
}

const trackableEvent = {
  eventQueue: [],
  trackEvent: function (name, properties) {
    if (window.trackingDisabled) return
    if (!window.User || window.User.isControlledBySu) return

    if (!window.mixpanel || !window.pipelineMixpanel.isReady) {
      this.eventQueue.push({ name: name, properties: { ...properties, location: getLocation(window.location.pathname) } })
    } else {
      window.mixpanel.track(name, _.extend(PpdStorage.get('mixpanel_superproperties'), properties, { location: getLocation(window.location.pathname) }))
    }
  },

  _drainEventQueue: function () {
    _.each(trackableEvent.eventQueue, function (ev) {
      trackableEvent.trackEvent(ev.name, ev.properties)
    })
  }
}

// Add the various methods to the trackableEvent object
addMethods(trackableEvent, methodsToAdd)

window.trackableEvent = trackableEvent
window.pipelineMixpanel.whenReady(trackableEvent._drainEventQueue)
jQuery(window.pipelineMixpanel.syncSuperproperties)

export default trackableEvent
