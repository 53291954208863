export default [
  'clickAcctSettingsNotification',
  'clickApplyFilters',
  'clickImportServicesAd',
  'clickShare',
  'createUser',
  'editUser',
  'hideModal',
  'pageView',
  'showModal',
  'submitRequestACallForm',
  'submitRequestDataImportForm',
  'timeoutActivityFeed',
  'timeoutActivityTotals',
  'timeoutListView',
  'useKeyboardShortcut'
]
