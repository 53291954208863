import React, { useMemo } from 'react'
import ReactTooltip from 'react-tooltip'

import store from 'store'
import { profilePush } from 'actions/index'
import addEditRecord from 'add_edit_modals'

import { PermissibleButton } from 'components/Permissible'
import { UPDATE, PERSON } from 'permissions'

import { Section, Row, Left, Right } from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

const PrimaryContact = () => {
  const { cardMeta: { showOnlyFields, showEditLink, showTooltips }, callbacks: { onClose }, resource } = useBusinessCard()

  if (!showOnlyFields.includes('primary_contact')) return null

  const primaryContactId = useMemo(() => resource.primary_contact_id || resource.primary_contact?.id, [JSON.stringify(resource)])
  const primaryContactName = useMemo(() => resource.primary_contact?.full_name || `${resource.primary_contact?.first_name || ''} ${resource.primary_contact?.last_name || ''}`, [JSON.stringify(resource)])

  if (!primaryContactId || !primaryContactName) return null

  const openPrimaryContactEditModal = e => {
    e.stopPropagation()
    if (onClose) onClose(false)
    addEditRecord({ resourceType: 'person', resourceId: primaryContactId })
  }

  const handlePrimaryContactClick = event => {
    store.dispatch(profilePush({ event, type: 'Person', id: primaryContactId }))
    if (onClose) onClose(false)
  }

  return (
    <Section data-cypress="business-card-primary-contact-name">
      <Row>
        <Left>
          Primary Contact
        </Left>

        <Right>
          <a href={`/people/${primaryContactId}`} onClick={handlePrimaryContactClick}>{primaryContactName}</a>
          {showEditLink && (
            <PermissibleButton
              action={UPDATE}
              cypress="bc-edit-primaryContact"
              policy={PERSON}
              user={window.User}
            >
              <button
                className="show-on-hover ui-corner-all btn btn-link normal"
                style={{ padding: 0, paddingLeft: 6 }}
                data-cypress="bc-edit-primary-contact"
                onClick={openPrimaryContactEditModal}
              >
                <span
                  data-class="tooltip-react tooltip-md"
                  data-for="edit-primary-contact-tooltip"
                  data-html="true"
                  data-tip="Edit"
                >
                  <i className="fas fa-pencil-alt" style={{ fontSize: '12px' }} />
                </span>
                {showTooltips && <ReactTooltip id="edit-primary-contact-tooltip" className="react-tooltip" />}
              </button>
            </PermissibleButton>
          )}
        </Right>
      </Row>
    </Section>
  )
}

export default PrimaryContact
