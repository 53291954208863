import moment from 'moment'
import update from 'immutability-helper'

import {
  PEOPLE_FETCH_DEALS,
  PEOPLE_FETCH_SUCCESS_MAILCHIMP,
  PERSON_CONDITIONAL_FIELDS_VALIDATION_UPDATE,
  PERSON_CREATE_FILE,
  PERSON_DEAL_NEW_SUCCESS,
  PERSON_DELETE_FILE,
  PERSON_DELETE_TODO,
  PERSON_FETCH_ERROR,
  PERSON_FETCH_SUCCESS_EMAIL_SEQUENCES,
  PERSON_FETCH_SUCCESS,
  PERSON_FETCH,
  PERSON_FILTER_EMAIL_SEQUENCES,
  PERSON_FILTER_FILES,
  PERSON_LOAD_FILES,
  PERSON_LOAD_TODOS,
  PERSON_PROFILE_FETCH,
  PERSON_PROFILE_RESET,
  PERSON_RELOAD,
  PERSON_SORT_EMAIL_SEQUENCES,
  PERSON_SORT_FILES,
  PERSON_UPDATE_FILE,
  PERSON_UPDATE_TODO_FILTERS,
  PERSON_UPDATE_TODO
} from 'People/constants'
import { ACTIVITY_ANY_TIME, GENERIC_FILTER_ANY } from 'Profiles/constants/activities'

import { FETCH_STATES } from 'Profiles/constants/fetch'

const userId = window.ppd && window.ppd.User ? window.ppd.User.id : 0

export const initialState = {
  personId: null,
  person: {},
  fetching: FETCH_STATES.fetching,
  campaign_members: [],
  email_sequences: [],
  agenda: [],
  agendaFilters: {
    ownerFilter: userId,
    dateFilter: 'ANY_TIME'
  },
  deals: [],
  dealsLoaded: false,
  dealSort: {
    field: 'updated_at',
    direction: 'desc'
  },
  dealFilters: {
    dealName: '',
    dealOwner: [],
    dealPrimaryContact: [],
    dealStage: [],
    dealStatus: [],
    dealUpdatedAt: {},
    dealValue: {}
  },
  activityFilters: {
    dateFilter: ACTIVITY_ANY_TIME,
    activityTabFilters: {
      [GENERIC_FILTER_ANY]: true
    },
    emailTabFilters: {
      [GENERIC_FILTER_ANY]: true,
      Email: false,
      'Trackable Email Action': false
    },
    searchQuery: '',
    currentTab: 'ALL'
  },
  files: [],
  fileSort: {
    field: 'created_at',
    direction: 'desc'
  },
  fileFilters: {
    fileTitle: '',
    fileOwner: [],
    fileUpdatedAt: {},
    fileSize: {},
    fileTags: []
  },
  mailchimpSort: {
    field: 'date_sent',
    direction: 'desc'
  },
  mailchimpFilters: {
    subject: '',
    title: '',
    opened: [],
    clicked: [],
    bounced: [],
    dateSent: 'ANY_TIME'
  },
  emailSequenceSort: {
    field: 'email_sequence_name',
    direction: 'asc'
  },
  emailSequenceFilters: {
    email_sequence_name: ''
  },
  reloading: false,
  conditionalFieldsValidation: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PERSON_RELOAD:
      return { ...state, reloading: true }
    case PERSON_PROFILE_RESET:
      return { ...initialState }
    case PERSON_PROFILE_FETCH:
      return { ...initialState, personId: action.payload.personId }
    case PERSON_FETCH:
      return { ...state, personId: action.payload.personId, fetching: FETCH_STATES.fetching }
    case PERSON_FETCH_SUCCESS:
      return {
        ...update(state, { person: { $set: action.payload } }),
        fetching: FETCH_STATES.fetchSuccess,
        reloading: false
      }
    case PERSON_FETCH_ERROR:
      return { ...initialState, fetching: { ...FETCH_STATES.fetchError, fetchError: action.payload } }
    case PERSON_DEAL_NEW_SUCCESS:
      return update(state, {
        deals: { $push: [action.payload] }
      })
    case PERSON_LOAD_TODOS:
      return update(state, {
        agenda: {
          $set: action.payload.map(todo => ({
            ...todo,
            moment_due_date:
              todo.unformatted_due_date && moment(todo.unformatted_due_date, ['MM-DD-YY', 'YYYY/MM/DD HH:mm:ss Z'])
          }))
        }
      })
    case PERSON_UPDATE_TODO:
      return { ...state, agenda: state.agenda.map(todo => (todo.id === action.payload.id ? action.payload : todo)) }
    case PERSON_DELETE_TODO:
      return { ...state, agenda: state.agenda.filter(todo => todo.id !== action.payload.id) }
    case PERSON_UPDATE_TODO_FILTERS:
      return { ...state, agendaFilters: Object.assign({}, state.agendaFilters, action.payload) }
    case PERSON_LOAD_FILES:
      return { ...state, files: action.payload }
    case PERSON_UPDATE_FILE:
      return { ...state, files: state.files.map(file => (file.id === action.payload.id ? action.payload : file)) }
    case PERSON_CREATE_FILE:
      return { ...state, files: state.files.concat([action.payload]) }
    case PERSON_DELETE_FILE:
      return { ...state, files: state.files.filter(file => file.id !== action.payload.id) }
    case PERSON_SORT_FILES:
      return { ...state, fileSort: action.payload }
    case PERSON_FILTER_FILES:
      return { ...state, fileFilters: action.payload }
    case 'PEOPLE_SORT_MAILCHIMP':
      return { ...state, mailchimpSort: action.payload }
    case 'PEOPLE_FILTER_MAILCHIMP':
      return { ...state, mailchimpFilters: action.payload }
    case PERSON_FILTER_EMAIL_SEQUENCES:
      return { ...state, emailSequenceFilters: action.payload }
    case PERSON_SORT_EMAIL_SEQUENCES:
      return { ...state, emailSequenceSort: action.payload }
    case PEOPLE_FETCH_DEALS:
      return { ...state, dealsLoaded: false }
    case 'PEOPLE_LOAD_DEALS':
      return { ...state, deals: action.payload, dealsLoaded: true }
    case 'PEOPLE_SORT_DEALS':
      return { ...state, dealSort: action.payload }
    case 'PEOPLE_FILTER_DEALS':
      return { ...state, dealFilters: action.payload }
    case 'PEOPLE_UPDATE_ACTIVITY_FILTERS':
      return { ...state, activityFilters: { ...state.activityFilters, ...action.payload } }
    case PEOPLE_FETCH_SUCCESS_MAILCHIMP:
      return update(state, {
        campaign_members: {
          $set: action.payload.map(mc => (
            { ...mc, moment_date_sent: mc.date_sent && moment(mc.date_sent, ['DD-MM-YY']) }
          ))
        }
      })
    case PERSON_FETCH_SUCCESS_EMAIL_SEQUENCES:
      return update(state, {
        email_sequences: {
          $set: action.payload
        }
      })
    case PERSON_CONDITIONAL_FIELDS_VALIDATION_UPDATE:
      return { ...state, conditionalFieldsValidation: action.payload }
    default:
      return state
  }
}
