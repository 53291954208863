import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useBusinessCard } from 'components/BusinessCard/context'

import { getUsers } from 'selectors/Account'

import {
  Section,
  Left,
  Row,
  Right,
  InterpunctJoin
} from 'components/BusinessCard/components/StyledComponents'

const DealOwner = () => {
  const {
    cardMeta: {
      showOnlyFields,
      showEmptyFields
    },
    resource: deal
  } = useBusinessCard()

  const allUsers = useSelector(getUsers)

  const userOwner = useMemo(() => {
    if (showOnlyFields.includes('user') || showOnlyFields.includes('owner')) { return `${deal.user.first_name} ${deal.user.last_name}` }
    if (showOnlyFields.includes('user_id')) { return allUsers.find(user => user.id === deal.user_id)?.full_name }
  }, [deal, showOnlyFields])

  const teamOwner = useMemo(() => {
    if (!showOnlyFields.includes('team_id')) { return null }
    return deal.team?.name
  }, [deal, showOnlyFields])

  const showOwner = useMemo(() => showOnlyFields.includes('user') || showOnlyFields.includes('owner') || showOnlyFields.includes('team_id') || showOnlyFields.includes('user_id'), [showOnlyFields])

  if (!showEmptyFields && !showOwner) return null

  return (
    <Section>
      <Row>
        <Left>Owner</Left>
        <Right><InterpunctJoin items={[userOwner, teamOwner].filter(Boolean)} /></Right>
      </Row>
    </Section>
  )
}

export default DealOwner
