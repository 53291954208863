export * from 'Profiles/constants/filters'

export const COMPANY_FETCH_SUCCESS = 'COMPANY_FETCH_SUCCESS'
export const DEAL_CONDITIONAL_FIELDS_VALIDATION = 'DEAL_CONDITIONAL_FIELDS_VALIDATION'
export const DEAL_CONDITIONAL_FIELDS_VALIDATION_UPDATE = 'DEAL_CONDITIONAL_FIELDS_VALIDATION_UPDATE'
export const DEAL_COPY = 'DEAL_COPY'
export const DEAL_FETCH = 'DEAL_FETCH'
export const DEAL_FETCH_ERROR = 'DEAL_FETCH_ERROR'
export const DEAL_FETCH_FILES = 'DEAL_FETCH_FILES'
export const DEAL_FETCH_SUCCESS = 'DEAL_FETCH_SUCCESS'
export const DEAL_MOVE_PIPELINE_MAX_COUNT = 30
export const DEAL_PERSON_FILTER_ALL = 'DEAL_PERSON_FILTER_ALL'
export const DEAL_PERSON_NEW_SUCCESS = 'DEAL_PERSON_NEW_SUCCESS'
export const DEAL_PROFILE_FETCH = 'DEAL_PROFILE_FETCH'
export const DEAL_PROFILE_RESET = 'DEAL_PROFILE_RESET'
export const DEAL_RELOAD = 'DEAL_RELOAD'
export const DEAL_RELOAD_TODOS = 'DEAL_RELOAD_TODOS'
export const DEAL_UPDATE = 'DEAL_UPDATE'
export const DEALS_FETCH_PEOPLE = 'DEALS_FETCH_PEOPLE'
export const DEALS_LOAD_PEOPLE = 'DEALS_LOAD_PEOPLE'
export const DEALS_UPDATE_ACTIVITY_FILTERS = 'DEALS_UPDATE_ACTIVITY_FILTERS'
