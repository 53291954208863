import PropTypes from 'prop-types'
import React from 'react'

import { P } from '@PipelineDeals/shared-react-components'
import { Section, Row, Left, Right, MapLink } from 'components/BusinessCard/components/StyledComponents'
import { useBusinessCard } from 'components/BusinessCard/context'

import styled from 'styled-components'

const StyledP = styled(P)`
  font-size: 14px;
`

const getPrefixedAddress = (record, addressType) => {
  const prefix = addressType.length ? `${addressType}_` : ''
  const label = addressType.length ? addressType : 'address'
  const arr = [record[`${prefix}address_1`], record[`${prefix}address_2`]]

  if (record[`${prefix}city`] || record[`${prefix}state`] || record[`${prefix}postal_code`]) {
    let addr = [record[`${prefix}city`], record[`${prefix}state`]].filter(el => el).join(', ')
    if (record[`${prefix}postal_code`]) addr = addr.concat(` ${record[`${prefix}postal_code`]}`)
    arr.push(addr)
  }
  arr.push(record[`${prefix}country`], record[`${prefix}address_google_maps_url`])
  return { label: label, formattedArray: arr }
}

const getNestedAddress = (data, addressType) => {
  const address = data.formatted_array
  address.push(data.google_maps_url)
  return { label: addressType, formattedArray: address }
}

const Addresses = ({ addressTypes }) => {
  const { cardMeta: { showOnlyFields }, resource } = useBusinessCard()
  const record = resource

  const addresses = addressTypes
    .filter(addressType => showOnlyFields.includes(addressType) || showOnlyFields.includes(`${addressType}_address`))
    .map((addressType) => {
      if (record[`${addressType}_address`] && record[`${addressType}_address`].has_address) {
        return getNestedAddress(record[`${addressType}_address`], addressType)
      } else if (record[addressType] && record[addressType].has_address) {
        return getNestedAddress(record[addressType], addressType)
      } else if (record[`has_${addressType}_address`] || addressType === '') {
        return getPrefixedAddress(record, addressType)
      }
    })
    .filter(address => address)

  if (!addresses.length && record.address_1) addresses.push(getPrefixedAddress(record, ''))

  if (!addresses.length) return null

  return (
    <>
      {
        addresses.map(({ label, formattedArray }) => {
          const googleMapsUrl = formattedArray.pop()

          return (
            <Section key={label}>
              <Row>
                <Left>{label.capitalize()}</Left>
                <Right style={{ display: 'flex' }}>
                  <div style={{ marginRight: '25px' }}>
                    {formattedArray.map((part, i) => (<StyledP key={i}>{part}</StyledP>))}
                  </div>
                  <MapLink
                    href={googleMapsUrl}
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    <i className="fas fa-map-marked-alt" />
                  </MapLink>
                </Right>
              </Row>
            </Section>
          )
        })
      }
    </>
  )
}

Addresses.propTypes = {
  addressTypes: PropTypes.array.isRequired
}

export default Addresses
